import React, { useEffect, useState } from 'react';
import ChecksheetForm from 'components/ChecksheetForm';
import { useQuery } from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import ChecksheetValidation from 'components/ChecksheetValidation';
import { fetchDplUndergradProgramsByAcadPlanCode } from 'apis/dplAPIs';
import NoChecksheetFound from 'components/NoChecksheetFound';
import { checksheetGet, checksheetGetHistory } from 'apis/checksheetAPIs';
import { useSelector, useDispatch } from 'react-redux';
import { setHistoryAll } from 'state/slices/historySlice';

const Checksheet = () => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const paramId = params.get('id');
  const paramAcadPlan = paramId.split('*')[2];
  const paramShowHistory = params.get('showHistory') === 'true';

  const [isLocked, setIsLocked] = useState(false);
  const [holdsLock, setHoldsLock] = useState(false);
  const [currentTab, setCurrentTab] = useState(paramShowHistory ? 2 : 0);

  const lockedBy = useSelector((state) => state.checksheet.lockedBy);
  const asurite = useSelector((state) => state.user.asurite);
  const role = useSelector((state) => state.user.role);

  const checksheetQuery = useQuery({
    queryKey: ['checksheet', paramId],
    queryFn: async () => {
      console.log('paramId', paramId);
      const token = await getAccessToken();
      return checksheetGet({ id: paramId, token });
    },
    enabled: !!paramId,
    retry: (failureCount, error) => {
      if (error.response.status === 404) return false; // Don't retry if it's a 404
      return failureCount < 3; // Retry up to 3 times for other errors
    },
  });

  const proposed = checksheetQuery?.data?.proposed ? true : false;

  const {
    data: fetchProgramData,
    // error: fetchProgramsError,
    // isError: fetchProgramsIsError,
    isSuccess: fetchProgramIsSuccess,
    isPending: fetchProgramIsLoading,
    fetchStatus: fetchProgramsFetchStatus,
  } = useQuery({
    queryKey: ['program', paramAcadPlan],
    queryFn: () => {
      return fetchDplUndergradProgramsByAcadPlanCode(paramAcadPlan);
    },
    enabled:
      !!paramAcadPlan &&
      checksheetQuery.fetchStatus === 'idle' &&
      (checksheetQuery.error?.response?.status === 404 || !proposed),
  });

  const {
    data: historyData,
    isSuccess: historySuccess,
    refetch: refetchHistory,
  } = useQuery({
    queryKey: ['checksheetHistory', paramId],
    queryFn: async () => {
      console.log('Getting history for paramId', paramId);
      const token = await getAccessToken();
      return checksheetGetHistory({ id: paramId, token });
    },
    enabled: !!paramId,
  });

  const handleRefetchChecksheet = () => {
    checksheetQuery.refetch();
    refetchHistory();
  };

  useEffect(() => {
    if (!!historyData && historySuccess) {
      console.log('historyData: ', historyData);

      const requirements = {}; // requirements history (add/modify)
      const subsections = {}; // subsections history (add/modify)
      const courseLists = {}; // courseLists history (add/modify)
      const deleted = {}; // requirements history (delete)
      const deletedCourseLists = {}; // courseLists history
      const updatedHistory = [];

      historyData.forEach((item) => {
        if (
          item.action.S === 'PROGRESS-MODIFY' ||
          item.action.S === 'MODIFY-PROGRESS'
        ) {
          const jsonDescription = JSON.parse(item.description.S);

          let jsonBefore = {};
          let jsonAfter = {};

          if (item.before) jsonBefore = JSON.parse(item.before.S);
          if (item.after) jsonAfter = JSON.parse(item.after.S);

          const updatedItem = {
            action: item.action.S,
            updatedOn: item.updatedOn.S,
            progress: jsonDescription.progress,
            progressAction: jsonDescription.action,
            asurite: jsonDescription.asurite,
            note: jsonDescription.note,
            before: jsonBefore,
            after: jsonAfter,
          };

          updatedHistory.push(updatedItem);
        }
        if (item.action.S === 'RECALL-RECALL') {
          const jsonDescription = JSON.parse(item.description.S);
          // console.log('JSON desc:', jsonDescription);

          let jsonAfter = {};
          if (item.after) jsonAfter = JSON.parse(item.after.S);

          const updatedItem = {
            action: item.action.S,
            updatedOn: jsonDescription.recalledBy.date,
            progress: null,
            progressAction: jsonDescription.action.toLowerCase(),
            asurite: jsonDescription.recalledBy.asurite,
            note: jsonDescription.recalledBy.note,
            role: jsonDescription.recalledBy.role,
            before: null,
            after: jsonAfter,
          };

          updatedHistory.push(updatedItem);
        }
        if (
          item.action.S === 'RECALL-APPROVE' ||
          item.action.S === 'RECALL-DENY'
        ) {
          const jsonDescription = JSON.parse(item.description.S);
          // console.log('JSON desc:', jsonDescription);

          let jsonBefore = {};
          if (item.before) jsonBefore = JSON.parse(item.before.S);

          const updatedItem = {
            action: item.action.S,
            updatedOn: item.updatedOn.S,
            progress: null,
            progressAction: jsonDescription.action.toLowerCase(),
            asurite: item.updatedBy.S,
            note: jsonDescription.note,
            role: jsonDescription.recalledBy.role,
            before: jsonBefore,
            after: null,
          };

          updatedHistory.push(updatedItem);
        }
        if (
          item.action.S !== 'REQUIREMENT-DELETE' &&
          item.action.S !== 'SUBSECTION-DELETED' &&
          item.action.S !== 'COURSE-LIST-DELETED'
        ) {
          let jsonAfter = {};

          if (item.after) jsonAfter = JSON.parse(item.after.S);

          // Create the updated item object
          const updatedItem = {
            action: item.action.S,
            updatedOn: item.updatedOn.S,
            updatedBy: item.updatedBy.S,
            before: item.before?.S || '',
            after: item.after?.S || '',
            courses: jsonAfter?.courses || [], // default to empty array if missing
            targetId: item.targetId?.S || '',
          };

          if (
            item.action.S === 'REQUIREMENT-MODIFY' ||
            item.action.S === 'REQUIREMENT-ADDED'
          ) {
            const requirementId = jsonAfter?.requirementId || '';
            // If the requirementId key doesn’t exist in the map, initialize it with an empty array
            if (!requirements[requirementId]) {
              requirements[requirementId] = [];
            }

            // Push the updatedItem to the array associated with this requirementId
            requirements[requirementId].push(updatedItem);
          }
          if (
            item.action.S === 'SUBSECTION-MODIFIED' ||
            item.action.S === 'SUBSECTION-ADDED'
          ) {
            const subsectionId = jsonAfter?.subsectionId || '';
            // If the requirementId key doesn’t exist in the map, initialize it with an empty array
            if (!subsections[subsectionId]) {
              subsections[subsectionId] = [];
            }

            // Push the updatedItem to the array associated with this requirementId
            subsections[subsectionId].push(updatedItem);
          }

          if (
            item.action.S === 'COURSE-LIST-MODIFY' ||
            item.action.S === 'COURSE-LIST-ADDED'
          ) {
            const courseListName = jsonAfter?.courseListName || '';
            // If the requirementId key doesn’t exist in the map, initialize it with an empty array
            if (!courseLists[courseListName]) {
              courseLists[courseListName] = [];
            }

            // Push the updatedItem to the array associated with this requirementId
            courseLists[courseListName].push(updatedItem);
          }
        } else if (
          item.action.S === 'REQUIREMENT-DELETE' ||
          item.action.S === 'COURSE-LIST-DELETED'
        ) {
          let jsonBefore = {};

          if (item?.before) JSON.parse(item.before.S);

          const updatedItem = {
            action: item.action.S,
            updatedOn: item.updatedOn.S,
            updatedBy: item.updatedBy.S,
            before: item.before?.S || '',
            after: item.after?.S || '',
            courses: jsonBefore?.courses || [], // default to empty array if missing
            targetId: item.targetId?.S || '',
          };
          // console.log('updatedItem: ', updatedItem);

          if (item.action.S === 'REQUIREMENT-DELETE') {
            const requirementId = jsonBefore?.requirementId || '';
            console.log('requirementId: ', requirementId);
            if (!deleted[requirementId]) {
              deleted[requirementId] = [];
            }
            deleted[requirementId].push(updatedItem);
          } else if (item.action.S === 'COURSE-LIST-DELETED') {
            const courseListName = jsonBefore?.courseListName || '';
            // console.log('courseListName: ', courseListName);
            if (!deletedCourseLists[courseListName]) {
              deletedCourseLists[courseListName] = [];
            }
            deletedCourseLists[courseListName].push(updatedItem);
          }
        }
      });

      dispatch(
        setHistoryAll({
          checksheetId: paramId,
          requirements: requirements,
          subsections: subsections,
          deleted: deleted,
          courseLists: courseLists,
          deletedCourseLists: deletedCourseLists,
          updatedHistory: updatedHistory,
        })
      );

      console.log('historyRequirements: ', requirements);
      console.log('historySubsections: ', subsections);
      console.log('historyDeleted: ', deleted);
      console.log('historyCourseLists: ', courseLists);
      console.log('historyDeletedCourseLists: ', deletedCourseLists);
      console.log('updatedHistory: ', updatedHistory);
    }
  }, [historyData, historySuccess, dispatch, paramId, refetchHistory]);

  useEffect(() => {
    setIsLocked(!!lockedBy && !!Object.keys(lockedBy).length);
    setHoldsLock(
      !!lockedBy &&
        !!Object.keys(lockedBy).length &&
        lockedBy.asurite === asurite
    );
  }, [lockedBy, asurite]);

  if (
    !proposed &&
    fetchProgramIsLoading &&
    fetchProgramsFetchStatus === 'fetching'
  ) {
    return <div className="container">Loading...</div>;
  }

  return (
    <>
      {checksheetQuery.isSuccess &&
      (proposed || (!proposed && fetchProgramIsSuccess)) ? (
        <div>
          <ChecksheetValidation
            checksheetId={paramId}
            isLocked={isLocked && !role.includes('DARS')}
            holdsLock={holdsLock}
            showHistory={paramShowHistory}
            handleRefetchChecksheet={handleRefetchChecksheet}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <div className="d-flex justify-content-end">
            <div className="bg-white" style={{ width: '80%' }}>
              <div className="px-0 text-gray-7">
                <ChecksheetForm
                  checkId={paramId}
                  checksheetQuery={checksheetQuery}
                  program={fetchProgramData}
                  isLocked={isLocked}
                  holdsLock={holdsLock}
                  refetchHistory={refetchHistory}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  proposed={proposed}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        checksheetQuery.isError && (
          <NoChecksheetFound
            checksheetId={paramId}
            program={fetchProgramData}
          />
        )
      )}
    </>
  );
};

export default Checksheet;
