import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchDplUndergradMinorsCertificates } from 'apis/dplAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { setUndergradDegreeList } from 'state/slices/dataListsSlice';

import {
  faTriangleExclamation,
  faCheckCircle,
  faCalendar,
  faSearch,
  faSortDown,
  faSortUp,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlanExtensionProgramRow from './PlanExtensionProgramRow';
import { setChecksheetList } from 'state/slices/dataListsSlice';
import { setYear } from 'state/slices/settingsSlice';
import { checksheetGetAllByYear } from 'apis/checksheetAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import StaticData from './StaticData';
import { Button } from '@asu/components-core';
import LoadingSkeleton from './LoadingSkeleton';
import {
  subplanActivationGetAll,
  submitPlanExtensions,
  getPlanExtensionsProgress,
} from 'apis/adminAPIs';
// import { Link } from 'react-router-dom';
import { classNames } from 'utils';

const PlanExtensionProgramRows = ({
  // degreeType,
  programList,
  fetchPrograms,
  checksheetList,
  refetchChecksheets,
  refetchPlanExtensionsProgress,
  getChecksheetIsLoading,
  setError,
  handleOpenDefinitions,
  user,
  year,
  progress,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const [searchString, setSearchString] = useState('');
  const [statusFilters] = useState([]);
  const [totalProgramsShown, setTotalProgramsShown] = useState(0);

  const [sortColumn, setSortColumn] = useState('programDescr'); // Default column
  const [sortOrder, setSortOrder] = useState('asc'); // Default order (ascending)

  const [showDiv, setShowDiv] = useState(false);
  const [showProgress, setShowProgress] = useState(true); // show progress bar
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setShowProgress(false); // Hides the div when clicked close button
  };

  const {
    data: fetchSubplanActivationListData,
    error: fetchSubplanActivationListError,
    isError: fetchSubplanActivationListIsError,
    isSuccess: fetchSubplanActivationListIsSuccess,
    // isPending: fetchSubplanActivationListIsPending,
    // fetchStatus: fetchSubplanActivationListFetchStatus,
  } = useQuery({
    queryKey: ['subplanActivationList', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return subplanActivationGetAll({ year: year, token });
    },
    enabled: isAuthenticated,
  });

  const handleSort = (column) => {
    setSortColumn((prevColumn) => {
      const newOrder =
        prevColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newOrder);
      return column; // Ensure the column is properly updated
    });
  };

  const sortedPrograms = useMemo(() => {
    return [...programList].sort((a, b) => {
      let valueA, valueB;

      // Ensure proper handling of sorting column
      if (sortColumn === 'planExtensionType') {
        valueA = a.planExtensionType ?? ''; // Default to empty string if undefined
        valueB = b.planExtensionType ?? '';
      } else if (sortColumn === 'acadSubPlanCode') {
        valueA = a.subplan ? a.subplan.acadSubPlanCode : '';
        valueB = b.subplan ? b.subplan.acadSubPlanCode : '';
      } else if (sortColumn === 'planExtensionDefaultFlag') {
        valueA = a.planExtensionDefaultFlag ?? ''; // Default to empty string if undefined
        valueB = b.planExtensionDefaultFlag ?? '';
      } else if (sortColumn === 'planExtensionUrl') {
        valueA = a.planExtensionUrl ?? ''; // Default to empty string if undefined
        valueB = b.planExtensionUrl ?? '';
      } else if (sortColumn === 'planExtensionStatus') {
        valueA = a.planExtensionStatus ?? ''; // Default to empty string if undefined
        valueB = b.planExtensionStatus ?? '';
      } else {
        valueA = a[sortColumn] ?? '';
        valueB = b[sortColumn] ?? '';
      }

      // Handle null or undefined values by pushing them to the bottom
      if (valueA == null) return sortOrder === 'asc' ? 1 : -1;
      if (valueB == null) return sortOrder === 'asc' ? -1 : 1;

      // Check if both values are numbers
      const isNumericA = !isNaN(valueA);
      const isNumericB = !isNaN(valueB);

      if (isNumericA && isNumericB) {
        return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
      }

      // Convert non-numeric values to lowercase strings for case-insensitive sorting
      const stringA = valueA.toString().toLowerCase();
      const stringB = valueB.toString().toLowerCase();

      return sortOrder === 'asc'
        ? stringA.localeCompare(stringB)
        : stringB.localeCompare(stringA);
    });
  }, [programList, sortColumn, sortOrder]);

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };
  let frameId = useRef(null);

  const handleScroll = useCallback(() => {
    frameId.current = requestAnimationFrame(() => {
      const scrollPosition = window.scrollY;
      setShowDiv(scrollPosition > 320);
    });
  }, []);

  // Function to submit all plans and trigger immediate refetch
  const handleSubmitAll = async () => {
    const confirmation = window.confirm(
      `You will submit all for year ${Number(year)} - ${Number(year) + 1}. The process will take more than 15 minutes. Be patient. Don't press the button multiple times. Continue?`
    );

    if (!confirmation) return;

    try {
      setIsSubmitting(true); // Hide the button and show "Submitting..."
      console.log('Submitting all programs...');

      const token = await getAccessToken();
      const jsonData = { year: year };
      await submitPlanExtensions({ jsonData, token });

      console.log('Submission successful!');
    } catch (error) {
      console.error('Error submitting all programs:', error);
      alert('An error occurred while submitting. Please try again.');
      setIsSubmitting(false); // Show button again in case of error
    }
  };

  // Auto-refresh data every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchChecksheets();
      refetchPlanExtensionsProgress();
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [refetchChecksheets, refetchPlanExtensionsProgress]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (frameId.current) cancelAnimationFrame(frameId.current);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (fetchPrograms.error) setError(fetchPrograms.error.message);
    if (fetchPrograms.isSuccess && fetchPrograms.fetchStatus === 'idle') {
      const next = [];

      let programsData = [];
      programsData = fetchPrograms.data;

      programsData.forEach((program) => {
        const found = checksheetList.find(
          (checksheet) =>
            checksheet.id.split('*')[2] === program.acadPlanCode &&
            checksheet.id.split('*')[3] === 'null'
        );

        // console.log('found===', found);
        if (found) {
          const obj = {
            acadPlanCode: program.acadPlanCode,
            acadPlanDescription: program.acadPlanMarketingDescription,
            degreeDescriptionShort: program.degreeDescriptionShort,
            campusesOffered: program.campusesOffered,
            acadPlanType: program.acadPlanType,
            owners: program.owners,
            subplan: null,
            programDescr: program.acadPlanMarketingDescription,

            planExtensionType: program?.subplan
              ? 'SP'
              : program?.acadPlanType === 'CER'
                ? 'CE'
                : program?.acadPlanType === 'MIN'
                  ? 'MI'
                  : 'MM',
            planExtensionDefaultFlag: found?.planExtensionsStatus?.defaultFlag,
            planExtensionUrl: found?.id
              ? `https://degrees.apps.asu.edu/checksheet/${found.id.replaceAll('*', '/')}`
              : '',
            planExtensionStatus: found?.planExtensionsStatus?.status,
          };
          next.push(obj);
        }

        if (fetchSubplanActivationListIsError)
          setError(fetchSubplanActivationListError.message);

        if (program.subplans && fetchSubplanActivationListIsSuccess)
          program.subplans.forEach((subplan) => {
            if (
              fetchSubplanActivationListData?.[program.acadPlanCode]?.[
                subplan.acadSubPlanCode
              ]
            ) {
              const found = checksheetList.find(
                (checksheet) =>
                  checksheet.id.split('*')[3] === subplan.acadSubPlanCode
              );

              if (found) {
                const subplanObj = {
                  acadPlanCode: program.acadPlanCode,
                  acadPlanDescription: program.acadPlanDescription,
                  degreeDescriptionShort: program.degreeDescriptionShort,
                  acadPlanType: program.acadPlanType,
                  campusesOffered: program.campusesOffered,
                  owners: program.owners,
                  subplan: subplan,
                  programDescr:
                    program.acadPlanDescription + ' - ' + subplan.description,
                  planExtensionType: 'SP',
                  planExtensionfaultFlag:
                    found?.planExtensionsStatus?.defaultFlag,
                  planExtensionUrl: found?.id
                    ? `https://degrees.apps.asu.edu/checksheet/${found.id.replaceAll('*', '/')}`
                    : '',
                  planExtensionStatus: found?.planExtensionsStatus?.status,
                };

                next.push(subplanObj);
              }
            }
          });
      });

      dispatch(setUndergradDegreeList([...next]));
    }
  }, [
    // degreeType,
    dispatch,
    fetchPrograms.data,
    fetchPrograms.error,
    fetchPrograms.fetchStatus,
    fetchPrograms.isSuccess,
    fetchSubplanActivationListData,
    fetchSubplanActivationListError,
    fetchSubplanActivationListIsError,
    fetchSubplanActivationListIsSuccess,
    checksheetList,
    setError,
  ]);

  useEffect(() => {
    setTotalProgramsShown(
      programList.filter(
        (program) =>
          `${program.programDescr}`
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          program.acadPlanCode
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          program.subplan?.acadSubPlanCode
            .toLowerCase()
            .includes(searchString.toLowerCase())
      ).length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilters, searchString, programList]);

  if (fetchSubplanActivationListIsError)
    return (
      <div>
        <div>
          An error has occurred: {fetchSubplanActivationListError.message}
        </div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      {showProgress ? (
        <div className="bg-success-custom p-2">
          <div className="container d-flex justify-content-between align-items-center">
            <div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success-custom px-1"
              />
              {'  '}
              {progress?.success} programs submitted successfully.
              <div className="mt-1">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="text-danger-custom px-1"
                />
                {'  '}
                {progress?.error} programs failed to submit.
              </div>
            </div>

            {/* Close button properly aligned to the right */}
            <div className="ms-auto p-2">
              <Button
                label="Close"
                color="gray"
                size="small"
                onClick={handleClose}
              />{' '}
              {/* Hide on click */}
            </div>
          </div>
        </div>
      ) : null}

      <div className="container">
        <div className="my-4">
          <div className="pt-1 mt-2 position-relative">
            {
              <div
                className={classNames(
                  showDiv ? 'visible opacity-100' : 'invisible opacity-0',
                  'container-xl position-fixed bg-white pt-4 program-list-header ps-0 pe-3'
                )}
                style={{ top: '89px', zIndex: 1 }}
              >
                <div className="d-flex justify-content-between mb-2">
                  {fetchPrograms.isPending ? (
                    <LoadingSkeleton width={'140px'} height={'25px'} />
                  ) : (
                    <span>
                      {totalProgramsShown} results{' '}
                      {!!searchString && (
                        <span>
                          for <span className="fw-bold">{searchString}</span>
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
                  {/* Program Column with Sorting */}
                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '23%' }}
                  >
                    Program
                    <button
                      onClick={() => handleSort('programDescr')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'programDescr' && sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>

                  {/* Plan Code Column with Sorting */}
                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '10%' }}
                  >
                    Plan Code
                    <button
                      onClick={() => handleSort('acadPlanCode')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'acadPlanCode' && sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>

                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '10%' }}
                  >
                    SubPlan Code
                    <button
                      onClick={() => handleSort('acadSubPlanCode')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'acadSubPlanCode' &&
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>

                  {/* Type Column with Sorting */}
                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '9%' }}
                  >
                    Type
                    <button
                      onClick={() => handleSort('planExtensionType')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'planExtensionType' &&
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>

                  {/* Default Column with Sorting */}
                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '9%' }}
                  >
                    Default
                    <button
                      onClick={() => handleSort('planExtensionDefaultFlag')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'planExtensionDefaultFlag' &&
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>

                  {/* URL Column with Sorting */}
                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '24%' }}
                  >
                    URL
                    <button
                      onClick={() => handleSort('planExtensionUrl')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'planExtensionUrl' &&
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>

                  {/* Peoplesoft Status Column with Sorting */}
                  <div
                    className="col-auto fw-bold d-flex justify-content-between align-items-center"
                    style={{ width: '15%' }}
                  >
                    Peoplesoft Status
                    <button
                      onClick={() => handleSort('planExtensionStatus')}
                      className="border-0 bg-transparent ms-2"
                    >
                      {sortColumn === 'planExtensionStatus' &&
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            }

            <div className="d-flex justify-content-between">
              <div className="mb-3 fs-4 fw-bold">
                Submit Programs to{' '}
                <span className="bg-asu-gold px-1">PeopleSoft</span>
              </div>
              {user.role.includes('PROVOST') && (
                <div
                  className=" p-2 d-flex gap-1 align-items-end my-1"
                  style={{ width: 'fit-content' }}
                >
                  {year ? (
                    isSubmitting ? (
                      <span className="text-danger-custom">
                        {' '}
                        <FontAwesomeIcon icon={faCog} size="2x" spin />
                        <span className="px-1 fw-bold">Submitting...</span>
                        <div className="ps-5 fw-bold">
                          The submission status refreshes every 10 seconds.
                        </div>
                      </span>
                    ) : (
                      <Button
                        label={`Submit all for ${Number(year)} - ${Number(year) + 1}`}
                        color="gold"
                        size="small"
                        onClick={handleSubmitAll} // Just calls handleSubmit
                      />
                    )
                  ) : null}
                </div>
              )}
            </div>

            <div className="d-flex gap-6 justify-content-between mb-6">
              <div className="d-flex gap-3">
                <div>
                  <div className="d-flex gap-1 align-items-center mb-1">
                    <FontAwesomeIcon icon={faSearch} />
                    <div className="fw-bold">Search</div>
                  </div>
                  <input
                    type="text"
                    placeholder={`Search ${'all programs'}`}
                    className="border-0 border-bottom border-gray-6 bg-transparent px-3 py-1"
                    style={{ width: '275px' }}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <div>
                  <div className="d-flex gap-1 align-items-center mb-1">
                    <FontAwesomeIcon icon={faCalendar} />
                    <div className="fw-bold text-nowrap">Catalog year</div>
                  </div>
                  <select value={year} onChange={(e) => handleSetYear(e)}>
                    {StaticData.yearList.map((year) => (
                      <option key={year.value} value={year.value}>
                        {year.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="d-flex px-2 justify-content-end mb-2">
              {fetchPrograms.isPending ? (
                <LoadingSkeleton width={'140px'} height={'25px'} />
              ) : (
                <span>
                  {totalProgramsShown} programs{' '}
                  {!!searchString && (
                    <span>
                      for <span className="fw-bold">{searchString}</span>
                    </span>
                  )}
                </span>
              )}
            </div>
            <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
              {/* Program Column with Sorting */}
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '23%' }}
              >
                Program
                <button
                  onClick={() => handleSort('programDescr')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'programDescr' && sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>

              {/* Plan Code Column with Sorting */}
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '10%' }}
              >
                Plan Code
                <button
                  onClick={() => handleSort('acadPlanCode')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'acadPlanCode' && sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '10%' }}
              >
                SubPlan Code
                <button
                  onClick={() => handleSort('acadSubPlanCode')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'acadSubPlanCode' && sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>

              {/* Type Column with Sorting */}
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '9%' }}
              >
                Type
                <button
                  onClick={() => handleSort('planExtensionType')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'planExtensionType' && sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>

              {/* Default Column with Sorting */}
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '9%' }}
              >
                Default
                <button
                  onClick={() => handleSort('planExtensionDefaultFlag')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'planExtensionDefaultFlag' &&
                  sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>

              {/* URL Column with Sorting */}
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '24%' }}
              >
                URL
                <button
                  onClick={() => handleSort('planExtensionUrl')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'planExtensionUrl' && sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>

              {/* Peoplesoft Status Column with Sorting */}
              <div
                className="col-auto fw-bold d-flex justify-content-between align-items-center"
                style={{ width: '15%' }}
              >
                Peoplesoft Status
                <button
                  onClick={() => handleSort('planExtensionStatus')}
                  className="border-0 bg-transparent ms-2"
                >
                  {sortColumn === 'planExtensionStatus' &&
                  sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </div>
            </div>

            {fetchPrograms.isPending && getChecksheetIsLoading ? (
              <div className="mt-1">
                {[...Array(8)].map((item, index) => (
                  <LoadingSkeleton key={index} width={'100%'} height={'65px'} />
                ))}
              </div>
            ) : (
              fetchPrograms.isSuccess &&
              (totalProgramsShown > 0 ? (
                sortedPrograms
                  .filter(
                    (program) =>
                      `${program.programDescr}}`
                        .toLowerCase()
                        .includes(searchString.toLowerCase()) ||
                      program.acadPlanCode
                        .toLowerCase()
                        .includes(searchString.toLowerCase()) ||
                      program.subplan?.acadSubPlanCode
                        .toLowerCase()
                        .includes(searchString.toLowerCase())
                  )
                  .map((program, index) => (
                    <PlanExtensionProgramRow
                      key={
                        program.subplan
                          ? program.acadPlanCode +
                            '*' +
                            program.subplan.acadSubPlanCode
                          : program.acadPlanCode
                      }
                      program={program}
                      activatedSubplans={
                        fetchSubplanActivationListData?.[program.acadPlanCode]
                      }
                      programList={programList}
                      checksheetList={checksheetList}
                      filters={statusFilters}
                      index={index}
                      refetchChecksheets={refetchChecksheets}
                      refetchPlanExtensionsProgress={
                        refetchPlanExtensionsProgress
                      }
                      isLoading={getChecksheetIsLoading}
                      handleOpenDefinitions={handleOpenDefinitions}
                      user={user}
                    />
                  ))
              ) : (
                <div>
                  No{' '}
                  {/* {degreeType === 'undergrad'
                    ? 'undergraduate degrees'
                    : degreeType === 'minor'
                      ? 'minor programs'
                      : degreeType === 'cert'
                        ? 'certificate programs'
                        : 'programs'}{' '} */}
                  found
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const PlanExtensionProgramList = ({ programType }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [progress, setProgress] = useState(null);

  const year = useSelector((state) => state.settings.year);
  const undergradDegreeList = useSelector(
    (state) => state.dataLists.undergradDegreeList
  );

  const checksheetList = useSelector((state) => state.dataLists.checksheetList);
  const role = useSelector((state) => state.user.role);
  const asurite = useSelector((state) => state.user.asurite);
  const userCollege = useSelector((state) => state.user.college);
  const userDepartment = useSelector((state) => state.user.department);

  const fetchUndergradDegrees = useQuery({
    queryKey: ['undergrad'],
    queryFn: fetchDplUndergradMinorsCertificates,
  });

  const {
    data: checksheetData,
    error: getChecksheetError,
    isError: getChecksheetIsError,
    isSuccess: getChecksheetIsSuccess,
    isLoading: getChecksheetIsLoading,
    refetch: refetchChecksheets,
  } = useQuery({
    queryKey: ['checksheets', year],
    queryFn: async () => {
      const token = await getAccessToken();

      return checksheetGetAllByYear({ year, token });
    },
    enabled: !!year,
  });

  const {
    data: planExtensionsProgress,
    isSuccess: getPlanExtensionsProgressIsSuccess,
    // isLoading: getPlanExtensionsProgressIsLoading,
    refetch: refetchPlanExtensionsProgress,
  } = useQuery({
    queryKey: ['planExtensionsProgress', '2025'],
    queryFn: async () => {
      const token = await getAccessToken();
      return getPlanExtensionsProgress({ year, token });
    },
    enabled: !!year,
  });

  useEffect(() => {
    if (getPlanExtensionsProgressIsSuccess) {
      setProgress(planExtensionsProgress);
      console.log('planExtensionsProgress:', planExtensionsProgress);
    }
  }, [getPlanExtensionsProgressIsSuccess, planExtensionsProgress, setProgress]);

  useEffect(() => {
    if (getChecksheetIsError) setError(getChecksheetError.message);

    if (getChecksheetIsSuccess) {
      const next = [];

      checksheetData.forEach((checksheet) => {
        const idYear = checksheet['sk'].S.split('*')[0];
        let lockedBy = {};

        if (!!checksheet['lockedBy']?.S) {
          lockedBy = JSON.parse(checksheet['lockedBy'].S);
        }

        const obj = {
          id: checksheet['sk']?.S ?? null,
          programType: checksheet['programType']?.S ?? null,
          checksheetObj: checksheet,
          catalogYear: idYear,
          status: checksheet['progress']?.S ?? null,
          updatedBy: checksheet['updatedBy']?.S ?? null,
          updatedOn: checksheet['updatedOn']?.S ?? null,
          lockedBy: lockedBy,
          planExtensionsStatus: checksheet['planExtensionsStatus']?.S
            ? JSON.parse(checksheet['planExtensionsStatus'].S)
            : null,
        };

        // console.log('checksheet===', obj);

        next.push(obj);
      });

      dispatch(setChecksheetList([...next]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checksheetData,
    getChecksheetError,
    getChecksheetIsError,
    getChecksheetIsSuccess,
  ]);

  if (getChecksheetIsError || fetchUndergradDegrees.isError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div>
        <PlanExtensionProgramRows
          // degreeType={'undergrad'}
          programList={undergradDegreeList}
          fetchPrograms={fetchUndergradDegrees}
          checksheetList={checksheetList}
          refetchChecksheets={refetchChecksheets}
          refetchPlanExtensionsProgress={refetchPlanExtensionsProgress}
          getChecksheetIsLoading={getChecksheetIsLoading}
          setError={setError}
          // handleOpenDefinitions={handleOpenDefinitions}
          user={{
            role,
            asurite,
            college: userCollege,
            department: userDepartment,
          }}
          year={year}
          progress={progress}
        />
      </div>
    </>
  );
};

export default PlanExtensionProgramList;
