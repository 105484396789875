import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import { faCalendar, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { templateGetAllByYear } from 'apis/templateAPIs';
import { useSelector, useDispatch } from 'react-redux';
import { setYear } from 'state/slices/settingsSlice';
import StaticData from './StaticData';
import TemplateRow from './TemplateRow';
import LoadingSkeleton from './LoadingSkeleton';
import { Button } from '@asu/components-core';
import { clearTemplate } from 'state/slices/templateSlice';
import { useNavigate } from 'react-router-dom';
// import TabPanel from './TabPanel';

const TemplateList = () => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [templateList, setTemplateList] = useState([]);
  const [sortBy, setSortBy] = useState(() => sortByNameAToZ);
  const [error, setError] = useState('');

  const year = useSelector((state) => state.settings.year);

  const {
    data: templateData,
    error: getTemplateError,
    isError: getTemplateIsError,
    isSuccess: getTemplateIsSuccess,
    isPending,
    refetch,
  } = useQuery({
    queryKey: ['templates', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return templateGetAllByYear({ year, token });
    },
    enabled: !!year,
  });

  function sortByNameAToZ(a, b) {
    if (a.details && b.details) {
      const nameA = a.details.templateName;
      const nameB = b.details.templateName;

      // Convert both names to lowercase for comparison
      const lowerA = nameA.toLowerCase();
      const lowerB = nameB.toLowerCase();

      // If lowercase letters are the same, sort by original case
      if (lowerA[0] === lowerB[0]) {
        return nameA < nameB ? -1 : 1;
      }

      // Otherwise sort by lowercase
      return lowerA < lowerB ? -1 : 1;
    }

    return 0;
  }

  function sortByNameZToA(a, b) {
    if (a.details && b.details) {
      const nameA = a.details.templateName;
      const nameB = b.details.templateName;

      // Convert both names to lowercase for comparison
      const lowerA = nameA.toLowerCase();
      const lowerB = nameB.toLowerCase();

      // If lowercase letters are the same, sort by original case
      if (lowerA[0] === lowerB[0]) {
        return nameA > nameB ? -1 : 1;
      }

      // Otherwise sort by lowercase
      return lowerA > lowerB ? -1 : 1;
    }

    return 0;
  }

  const handleNewTemplate = () => {
    dispatch(clearTemplate());
    navigate('/template');
  };

  const handleSortBy = (e) => {
    const value = e.target.value;

    switch (value) {
      case 'AToZ':
        setSortBy(() => sortByNameAToZ);
        break;
      case 'ZToA':
        setSortBy(() => sortByNameZToA);
        break;
      default:
        break;
    }
  };

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };

  useEffect(() => {
    if (getTemplateError) setError(getTemplateError.message);

    if (getTemplateIsSuccess) {
      const next = [];

      templateData.forEach((template) => {
        if (template['details']) {
          const jsonObject = JSON.parse(template['details'].S);
          const yearObject = StaticData.yearList.find(
            (obj) => obj.value === jsonObject.year
          );

          const obj = {
            id: template['sk'].S,
            details: jsonObject,
            templateObj: template,
            catalogYear: yearObject,
            updatedBy: template['updatedBy'].S,
            updatedOn: template['updatedOn'].S,
          };

          next.push(obj);
        }
      });

      next.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));

      setTemplateList([...next]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData, getTemplateIsSuccess]);

  if (getTemplateIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="fs-1 fw-bold">Templates</div>
        <div className="d-flex gap-3 align-items-center">
          <div className="d-flex gap-2">
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faCalendar} />
                <div className="fw-bold text-nowrap">Catalog year</div>
              </div>
              <select value={year} onChange={(e) => handleSetYear(e)}>
                {StaticData.yearList.map((year) => (
                  <option key={year.value} value={year.value}>
                    {year.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faSort} />
                <div className="fw-bold">Sort by</div>
              </div>
              <select onChange={(e) => handleSortBy(e)}>
                <option value="AToZ">Name (A-Z)</option>
                <option value="ZToA">Name (Z-A)</option>
              </select>
            </div>
          </div>
          <Button
            label="New template"
            color="gold"
            icon={['fas', 'plus']}
            onClick={handleNewTemplate}
          />
        </div>
      </div>
      <div>
        <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
          <div className="col-auto fw-bold ps-0" style={{ width: '40%' }}>
            Name
          </div>
          <div className="col-auto fw-bold" style={{ width: '19%' }}>
            Program type
          </div>
          <div className="col-auto fw-bold" style={{ width: '14%' }}>
            Last modified by
          </div>
          <div className="col-auto fw-bold" style={{ width: '14%' }}>
            Last modified on
          </div>
          <div className="col-auto" style={{ width: '13%' }} />
        </div>
        {isPending ? (
          <div className="mt-1">
            {[...Array(6)].map((item, index) => (
              <LoadingSkeleton key={index} width={'100%'} height={'64px'} />
            ))}
          </div>
        ) : (
          templateList
            .toSorted(sortBy)
            .map((template, index, array) => (
              <TemplateRow
                key={template.id}
                template={template}
                last={index === array.length - 1}
                refetch={refetch}
              />
            ))
        )}
      </div>
      {/* <div className="pt-1 mt-2">Sequence templates</div>
      </TabPanel> */}
    </div>
  );
};

export default TemplateList;
