import React, { useEffect } from 'react';
import ProgramList from 'components/ProgramList';
import { useQuery } from '@tanstack/react-query';
import { fetchDplColleges } from 'apis/dplAPIs';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useServiceAuth from 'auth/useServiceAuth';
import { useSelector } from 'react-redux';
import { checksheetGetAllRecallRequests } from 'apis/checksheetAPIs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@asu/components-core';

const Programs = () => {
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const location = useLocation();

  const [collegeName, setCollegeName] = useState([]);
  const [error, setError] = useState(null);
  const [requestsByCollege, setRequestsByCollege] = useState({});
  const [totalRequests, setTotalRequests] = useState(0);

  const year = useSelector((state) => state.settings.year);
  const role = useSelector((state) => state.user.role);
  const userCollege = useSelector((state) => state.user.college);

  const userIsProvost = role?.includes('PROVOST');

  const parsedRole = JSON.parse(role);

  const getInitialParams = () => {
    const params = new URLSearchParams(location.search);
    const paramsObject = {};

    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    return paramsObject;
  };

  const [queryParams, setQueryParams] = useState(getInitialParams());

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const { data: recallRequestData, isPending: recallRequestIsLoading } =
    useQuery({
      queryKey: [
        'checksheetrecallrequests',
        year,
        parsedRole ? parsedRole[0] : '',
      ],
      queryFn: async () => {
        const token = await getAccessToken();
        const result = await checksheetGetAllRecallRequests({
          year,
          role: JSON.parse(role)[0],
          token,
        });
        return result;
      },
      enabled:
        !!year &&
        !!parsedRole &&
        isAuthenticated &&
        (userIsProvost || role.includes('COLLEGE')),
    });

  const { data: recallRequestDarsData, isPending: recallRequestDarsIsLoading } =
    useQuery({
      queryKey: ['checksheetrecallrequests', year, 'DARS'],
      queryFn: async () => {
        const token = await getAccessToken();
        const result = await checksheetGetAllRecallRequests({
          year,
          role: 'DARS',
          token,
        });
        return result;
      },
      enabled: isAuthenticated && userIsProvost,
    });

  useEffect(() => {
    if (!!recallRequestData?.body && !recallRequestIsLoading) {
      const requestsByCollegeObj = {};

      JSON.parse(recallRequestData.body).forEach((request) => {
        const splitId = request.checksheet.split('*');

        if (!requestsByCollegeObj[splitId[1]])
          requestsByCollegeObj[splitId[1]] = [request];
        else requestsByCollegeObj[splitId[1]].push(request);
      });

      if (
        userIsProvost &&
        !!recallRequestDarsData?.body &&
        !recallRequestDarsIsLoading
      ) {
        JSON.parse(recallRequestDarsData.body).forEach((request) => {
          const splitId = request.checksheet.split('*');

          if (!requestsByCollegeObj[splitId[1]])
            requestsByCollegeObj[splitId[1]] = [request];
          else requestsByCollegeObj[splitId[1]].push(request);
        });
      }

      setRequestsByCollege(requestsByCollegeObj);
      setTotalRequests(Object.keys(requestsByCollegeObj).length);
    }
  }, [
    recallRequestDarsData,
    recallRequestDarsIsLoading,
    recallRequestData,
    recallRequestIsLoading,
    userIsProvost,
  ]);

  useEffect(() => {
    if (fetchCollegesError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      if (queryParams.college !== 'OTHER') {
        const collegeFound = fetchCollegesData.find(
          (college) => college.acadOrgCode === queryParams.college
        );

        if (!!collegeFound) setCollegeName(collegeFound.alphaSortDescription);
      } else setCollegeName('Other');
    }
  }, [
    fetchCollegesData,
    fetchCollegesError,
    fetchCollegesIsSuccess,
    queryParams.college,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('params', params);
    const paramsObject = {};

    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    setQueryParams(paramsObject);
  }, [location.search]);

  if (fetchCollegesIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div className="my-4">
      <div className="d-flex justify-content-between">
        <div className="mb-3">
          <Link to={'/'} className="text-maroon">
            Home
          </Link>
          <span className="text-gray-5 mx-1">/</span>
          {queryParams.college ? collegeName : 'All colleges/schools'}
        </div>
        {((!!queryParams.college &&
          !!requestsByCollege?.[queryParams.college]?.length &&
          role.includes('COLLEGE') &&
          userCollege === queryParams.college) ||
          (!queryParams.college && !!totalRequests && userIsProvost)) && (
          <div
            className="recall-requests-notification p-2 d-flex gap-1 border-start border-8 border-asu-pink align-items-center mt-1 mb-4"
            style={{ width: 'fit-content' }}
          >
            <div
              className="bg-asu-pink rounded-circle"
              style={{ width: '32px', height: '32px' }}
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-white m-1"
                style={{ width: '16px', height: '16px' }}
              />
            </div>
            <span className="fw-bold">
              {!!queryParams.college
                ? requestsByCollege[queryParams.college].length
                : totalRequests}{' '}
              recall requests pending review
            </span>
            <Button
              label="View requests"
              color="gold"
              size="small"
              element={Link}
              to={
                !!queryParams.college
                  ? `/recall-requests?college=${queryParams.college}`
                  : '/recall-requests'
              }
            />
          </div>
        )}
      </div>
      <ProgramList
        collegeCode={queryParams.college}
        collegeName={collegeName}
        programType={queryParams.program}
      />
    </div>
  );
};

export default Programs;
